import MessageSmsRepository from '@/shared/http/repositories/socialProject/message-sms'
import StudentRepository from '@/shared/http/repositories/socialProject/student'
import SMSRepository from '@/shared/http/repositories/socialProject/sms'
import { globalGetters as AuthGetters } from '@/store/modules/auth/getters'
import { dateFormattedFilter } from '@/shared/filters/ToDateFormatted'
import { loading, toast } from '@/shared/utils/UIHelper'
import { IsSuccessCode } from '@/shared/utils/API'
import { v4 as uuid } from 'uuid'
import { mapGetters } from 'vuex'
import { uniqBy } from 'lodash'
import moment from 'moment'

export default {
  name: 'SendSms',

  props: {
    item: {
      type: Array,
      default: []
    },
		specialCharacters: {
      type: Array,
      default: []
    },
		isStudentSms: {
      type: Boolean,
      default: false
    },
  },

  data: () => ({
    emptyText: 'Nenhum registro para mostrar',
    criteriaMessage: { title: null, message: null },
    disableTextAreaSMS:false,
    listToSendSms: [],
		messages: [],
    showSendSms: false,
		messageSMS: null,
    cardIndex: null,
    itemsIsNotListed: [],
    fieldsSMS: [
      {
        key: 'name',
        label: 'CRIANÇA',
        class: 'left-header-border text-center',
        sortable: true
      },
      {
        key: 'typeAndRespName',
        label: 'TIPO/RESPONSÁVEL',
        class: 'middle-header text-center',
        formatter: (value, key, item) => {
          if(!item) return

          const contacts = uniqBy(item.contacts, 'name').map(x => ({
            contactName: x.name.toUpperCase(),
            contactTypeName: x.contactTypeName
          }))
          return value = contacts
        }
      },
      {
        key: 'phone1',
        label: 'TELEFONE 1',
        class: 'middle-header text-center',
        formatter: (value, key, item) => {
          const phones = item.contacts.map(x => ({
            contactName: x.name,
            phone1: x.phone1 || x.phone,
            itemId: item.waitingListId || item.studentId,
            name: item.name,
            id: uuid()
          }))
          return value = phones
        }
      },
      {
        key: 'phone2',
        label: 'TELEFONE 2',
        class: 'middle-header text-center',
        formatter: (value, key, item) => {
          const phones = item.contacts.map(x => ({
            contactName: x.name,
            phone2: x.phone2,
            itemId: item.waitingListId || item.studentId,
            name: item.name,
            id: uuid()
          }))
          return value = phones
        }
      },
      {
        key: 'phone3',
        label: 'TELEFONE 3',
        class: 'right-header-border text-center',
        formatter: (value, key, item) => {
          const phones = item.contacts.map(x => ({
            contactName: x.name,
            phone3: x.phone3,
            itemId: item.waitingListId || item.studentId,
            name: item.name,
            id: uuid()
          }))
          return value = phones
        }
      },
    ],
		paginationMessageSms: {
      count: 1,
      limit: 4,
      index: 1
    }
  }),

  created() {
    this.getAllMessages()
	},

	computed: {
		...mapGetters({
      loggedInUser: AuthGetters.LOGGED_IN_USER
    }),
    onShowMessageHasArrivedCaracteresMax() {
      if (this.messageSMS.length >= 141 && this.messageSMS.length <= 240) return true
      
      return false
    }
	},

  watch: {
    item: {
      handler: function (value) {
        if (value.length > 0) {
          this.cardIndex = null
          this.messageSMS = null
          this.listToSendSms = []
          this.itemsIsNotListed = []
          this.showSendSms = false
        }
      },
      immediate: true
    }
  },
  
  methods: {
		checkPhoneNumber(item) {
      if (!item) return

      const isSelected = this.listToSendSms.some(x => x.name == item.name)
      
      if (isSelected) {
        const phoneAlreadySelected = this.listToSendSms.some(x => x.name == item.name
            && (x.phone1 != item.phone1 || x.phone2 != item.phone2 || x.phone3 != item.phone3))
        if (phoneAlreadySelected) {
          toast.info('Criança já possui um telefone selecionado!', 'CRIANÇA JÁ SELECIONADA')
          return
        }
        const idx = this.listToSendSms.findIndex(x => x.name == item.name)
        this.listToSendSms.splice(idx, 1)
        document.getElementById(item.id).style = 'background-color: #bdc6db; font-size: 13px;'
      } else {
        this.listToSendSms.push(item)
        document.getElementById(item.id).style = 'background-color: #1BA681; font-size: 13px;'
      }
    },

    prepareSendSMS () {
      if ((this.messageSMS == null || this.messageSMS.trim() == '') || this.listToSendSms.length == 0) {
        toast.info('Selecione uma mensagem á ser enviada e os telefones celulares', 'ENVIO DE SMS')
        return
      }

      const currentStringByteCount = this.stringByteCount(this.messageSMS)
      if (currentStringByteCount > 240) {
        toast.info('Peso da mensagem acima do limite', 'PESO DA MENSAGEM')
        return
      }

      this.itemsIsNotListed = []

      const objects = this.item
      for (let i = 0; i < objects.length; i++) {
        for (let z = 0; z < this.listToSendSms.length; z++) {
          if (objects[i].name == this.listToSendSms[z].name) {
            const messageformatted = this.changeTextToSms(objects[i], false) 
            this.listToSendSms[z].message = messageformatted
          }
        }
      }

      const onlyName = objects.map(x => x.name)
      const onlyNameSelected = this.listToSendSms.map(x => x.name)

      onlyName.forEach(element => {
        let isNotHave = onlyNameSelected.indexOf(element)
        if (isNotHave == - 1) {
          return this.itemsIsNotListed.push(element)
        }
      })
      
      this.showSendSms = true
    },

    changeTextToSms(item, toPreview) {
      if (this.messageSMS == null || this.messageSMS.trim() == '') {
        toast.info('Selecione um card ou escreva uma mensagem avulsa', 'MENSAGEM SMS')
        return
      }
      const checkObjInList = this.listToSendSms.some(x => x.name == item.name)
      if (!checkObjInList) {
        toast.info('Este cadastro não foi selecionado', 'MENSAGEM SMS')
        return
      }

      this.previewMessageSms = null
			
      const text = this.messageSMS
			const statusUpper = item.studentStatus ? item.studentStatus.toUpperCase() : ''
      const todayDate = moment().format("DD/MM/YYYY")
      const tomorrowDate = moment().add(1,'days').format("DD/MM/YYYY")
      const yesterdayDate = moment().add(-1,'days').format("DD/MM/YYYY")
      const respSelected = this.listToSendSms.find(x => x.name == item.name).contactName
      const respSelectedNameShort = this.cutName(respSelected)
			const formattedBirth = item.studentBirth ? dateFormattedFilter(item.studentBirth) : ''
			const dateTest = item.date ? dateFormattedFilter(item.date) : ''
			const studentCPF = item.studentCPF ? item.studentCPF : ''
			const studentRG = item.studentRG ? item.studentRG : ''
			const studentCode = item.studentCode ? item.studentCode : ''
			const studentReenrollment = item.studentReenrollment ? item.studentReenrollment : ''
      const studentName = item.name ? this.cutName(item.name) : ''
      const message = text.replace('@RESPNAME@', respSelectedNameShort)
                          .replace('@STUDENTNAME@', studentName)
                          .replace('@DATENOW@', todayDate)
                          .replace('@DATETOMORROW@', tomorrowDate)
                          .replace('@DATEYESTERDAY@', yesterdayDate)
                          .replace('@DATETEST@', dateTest)
                          .replace('@STUDENTSTATUS@', statusUpper)
                          .replace('@STUDENTBIRTH@', formattedBirth)
                          .replace('@STUDENTCODE@', studentCode)
                          .replace('@STUDENTCPF@', studentCPF)
                          .replace('@STUDENTRG@', studentRG)
                          .replace('@STUDENTREENROLMENT@', studentReenrollment)
				
      if (toPreview) {
        this.previewMessageSms = message
        this.toast(item, message)
        return
      }

      return message
    },

    cutName(name) {
      if (name == 'N/D') return 'N/D'

      const fullName = name
      const firstName = fullName.split(" ")[0]
      const fullNameLength = fullName.split(" ").length
      const lastName = fullName.split(" ")[fullNameLength-1] 
      const shortName = firstName + ' ' + lastName

      return shortName
    },

		async sendSMS() {
      loading.push()
      const batchSmsItems = this.listToSendSms.map(x => ({
        messageSms: x.message.toLowerCase(),
        phoneNumber: (x.phone || x.phone1) || x.phone2 || x.phone3
      }))

      await SMSRepository.SendSMS(batchSmsItems)
        .then(async res => {
          if (!IsSuccessCode(res)) return Promise.reject()

          if (this.isStudentSms) { // Só salva o histórico se for aluno
            for (let i = 0; i < this.listToSendSms.length; i++) {

              const smsModel = {
                studentId: this.listToSendSms[i].itemId,
                messageOwner: this.loggedInUser.name,
                historicDate: null,
                message: this.listToSendSms[i].message,
                phone: (this.listToSendSms[i].phone || this.listToSendSms[i].phone1) || this.listToSendSms[i].phone2 || this.listToSendSms[i].phone3 
              }
              await this.saveStudentHistoricSms(smsModel)
            }
          }

          toast.success('O SMS foi disparado!', 'ENVIO DE SMS')
          this.showSendSms = false
          this.messageSMS = null
          this.cardIndex = null
          this.studentsIsNotListed = []
          this.disableTextAreaSMS = false
          
          this.closeModalSendSMS()

          loading.pop()
          return Promise.resolve()
        })
        .catch(() => {
          loading.pop()
          toast.error('Erro ao enviar SMS', 'ERRO')
        })
    },

    async saveStudentHistoricSms(smsModel) {
      loading.push()

      await StudentRepository.SaveHistoricSms(smsModel)
        .then(res => {
          if (!IsSuccessCode(res)) return Promise.reject()

          loading.pop()
          return Promise.resolve()
        })
        .catch(() => {
          loading.pop()
          toast.error('Erro ao salvar SMS', 'ERRO')
        })
    },

    getAllMessages() {
      this.cardIndex = null
      loading.push()
      MessageSmsRepository.GetAll(this.criteriaMessage, this.paginationMessageSms)
        .then(res => {
          if (!IsSuccessCode(res)) return Promise.reject()

          this.messages = res.data.data

          this.paginationMessageSms = {
            count: res.data.count,
            limit: res.data.pagingLimit,
            index: res.data.pagingIndex
          }
          
          loading.pop()
          return Promise.resolve()
        })
        .catch(() => {
          loading.pop()
          toast.error('Erro ao carregar mensagens', 'ERRO')
        })
    },

    addMsgToTextArea(msg, idx) {
      if (this.listToSendSms.length == 0) {
        this.messageSMS = null
        this.cardIndex = null
        toast.info('Selecione primeiro os números celulares', 'ENVIO DE SMSs')
        return
      }
      this.cardIndex = null
      this.messageSMS = null
      this.messageSMS = msg
      this.cardIndex = idx
    },

		enableTextAreaSMS() {
      this.messageSMS = null
      this.cardIndex = null
      this.disableTextAreaSMS = !this.disableTextAreaSMS
      if (!this.disableTextAreaSMS) {
        this.messageSMS = null
        return
      }
      this.messageSMS = 'ABDA: '
    },

		validatePhone(phone) {
      if (!phone) return false

      const formattedPhone = this.clearNumber(phone)
      let regex = new RegExp('[1-9]{2}[6-9][0-9]{3}[0-9]{5}')
      let result = regex.test(formattedPhone)

      if (!result) return false
      
      return true
    },

    stringByteCount(s) { return encodeURI(s).split(/%..|./).length - 1 },

    clearNumber(number) {
      if (!number) return

      const numberFormatted = number
        .replace('-', '')
        .replace('(', '')
        .replace(')', '')
        .replace(' ', '')
      return numberFormatted
    },

    closeModalSendSMS() {
      this.$emit('onClearItem')
      this.cardIndex = null
      this.messageSMS = null
      this.listToSendSms = []
      this.itemsIsNotListed = []
      this.showSendSms = false
    },

    goToNewMessage() { window.location='/cadastros/mensagem-sms/novo' }
  }
}