import SocialProjectManager from '../../clients/abdaSocialProjectManager'
//import { merge } from 'lodash'

const resourceName = 'sms'
const GetSMSAttributes = () => SocialProjectManager.get(`${resourceName}/get-attribute-sms/`)
const GetSMSLogsSuccess = criteria => SocialProjectManager.get(`${resourceName}/get-sms-logs-success`, { 
  params: criteria
})
const GetSMSLogsFailure = criteria => SocialProjectManager.get(`${resourceName}/get-sms-logs-failure`, { 
  params: criteria
})
const SendSMS = data => SocialProjectManager.post(`${resourceName}/send-sms/`, data)
const GetPersonByPhoneNumber = data => SocialProjectManager.post(`${resourceName}/persons`, data)

const SendSmsRepository = {
  GetSMSAttributes,
  SendSMS,
  GetSMSLogsSuccess,
  GetSMSLogsFailure,
  GetPersonByPhoneNumber
}

export default SendSmsRepository